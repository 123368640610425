import React from "react"
import Slider from "react-slick"
import { useRecoilState } from "recoil"
import styled from "styled-components"

import arrow from "../../images/arrow2x.png"
import isOpenAtom from "./isOpenAtom"
const Wrapper = styled.div`
  border-bottom-left-radius: 36px;
  border-top-right-radius: 36px;
  background: #f9f9f9;
  overflow: hidden;
  box-shadow: 0 2 20 0 #000000;
`

const ImageBox = styled.div`
  border-bottom-left-radius: 36px;
  overflow: hidden;
  height: 210px;
  width: 100%;
  position: relative;
`

const ImageWrapper = styled.img`
  height: 210px;
  width: 100%;
  object-fit: cover;
`

const CloseButton = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  color: #ffffff;
  font-family: Palanquin;
  text-align: right;
  font-size: 14px;
  font-weight: medium;
  line-height: 20px;
  width: 107px;
  height: 26px;
  background: #ee751a;
  border-top-right-radius: 36px;
  border-bottom-left-radius: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 3px;
  &:hover {
    cursor: pointer;
  }
  z-index: 99;
`

const CloseIcon = styled.img`
  width: 6px;
  margin-bottom: 0;
  margin-right: 6px;
`

const LivePortfolio = styled.p`
  width: 100%;
  padding-left: 33px;
  background-color: #6fc2b4;
  font-size: 14px;
  color: #ffffff;
  font-weight: 700;
  line-height: 20px;
  height: 26px;
  border-top-right-radius: 36px;
  position: absolute;
  bottom: 0;
  margin-bottom: 0;
  line-height: 26px;
`

const HistoricPortfolio = styled.p`
  width: 100%;
  padding-left: 33px;
  background-color: #bfd104;
  font-size: 14px;
  color: #ffffff;
  font-weight: 700;
  line-height: 20px;
  height: 26px;
  border-top-right-radius: 36px;
  position: absolute;
  bottom: 0;
  margin-bottom: 0;
  line-height: 26px;
`

const Content = styled.div`
  margin: 21px 23px 36px 32px;
`

const Name = styled.p`
  width: 100%;
  color: #003d6a;
  font-family: Palanquin;
  font-size: 22px;
  font-weight: 600;
  line-height: 25px;
  margin: 0;
`

const ContentList = styled.div`
  margin: 15px 0px;
`

const LocationInfo = styled.div`
  display: flex;
  margin-bottom: 10px;
`

const InfoType = styled.p`
  width: 77px;
  color: #003d6a;
  font-family: Palanquin;
  font-size: 14px;
  font-weight: medium;
  line-height: 20px;
  margin: 0;
`

const InfoContent = styled.p`
  &,
  & a {
    flex: 1;
    margin: 0;
    margin-left: 10px;
    color: #003d6a;
    font-family: Palanquin;
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
  }

  & a {
    margin-left: 0;
  }
`

const Link = styled.a`
  text-decoration: none;
`

const MainOccupiers = styled.p`
  color: #003d6a;
  font-family: Palanquin;
  font-size: 14px;
  font-weight: medium;
  line-height: 20px;
  align-self: flex-start;
  margin: 0;
  margin-bottom: 10px;
`

const MainOccupiersContent = styled.p`
  color: #003d6a;
  font-family: Palanquin;
  font-size: 14px;
  font-weight: 300;
  line-height: 22px;
  margin: 0;
  margin-bottom: 20px;
`

const InfoContentAddress = styled.p`
  flex: 1;
  margin: 0;
  margin-left: 10px;
  color: #003d6a;
  font-family: Palanquin;
  font-size: 14px;
  font-weight: 300;
  line-height: 22px;
`

const settings = {
  // centerMode: true,
  dots: false,
  arrows: false,
  appendArrows: false,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  speed: 2000,
  autoplay: true,
  autoplaySpeed: 2000,
  draggable: true,
  accessibility: false,
  swipe: true,
}

const OpenWrapperLocation = ({ location, ...props }) => {
  const [isOpen, setOpen] = useRecoilState(isOpenAtom)

  const CloseLocation = () => {
    setOpen(false)
  }
  if (location?.gallery?.length > 0) {
    console.log(location?.gallery)
  }
  return (
    <Wrapper {...props}>
      <ImageBox>
        <CloseButton
          onClick={() => {
            CloseLocation()
          }}
        >
          <CloseIcon src={arrow} />
          Close
        </CloseButton>
        {location?.gallery?.length == 1 && (
          <ImageWrapper src={location?.gallery[0]?.sourceUrl} />
        )}
        {location?.gallery?.length > 1 && (
          <Slider {...settings}>
            {location.gallery.map(item => (
              <ImageWrapper src={item?.sourceUrl} />
            ))}
          </Slider>
        )}
        {location.live ? (
          <LivePortfolio>Live Portfolio</LivePortfolio>
        ) : (
          <HistoricPortfolio>Historic Portfolio</HistoricPortfolio>
        )}
      </ImageBox>
      <Content>
        <Name>{location?.name}</Name>
        <ContentList>
          {location?.city && (
            <LocationInfo>
              <InfoType>City:</InfoType>
              <InfoContent>{location?.city}</InfoContent>
            </LocationInfo>
          )}
          {location?.country && (
            <LocationInfo>
              <InfoType>Country:</InfoType>
              <InfoContent>{location?.country}</InfoContent>
            </LocationInfo>
          )}
          {location?.type && (
            <LocationInfo>
              <InfoType>Type:</InfoType>
              <InfoContent>{location?.type}</InfoContent>
            </LocationInfo>
          )}
          {location?.size && (
            <LocationInfo>
              <InfoType>Size:</InfoType>
              <InfoContent>
                {location?.size?.toLocaleString()} {location?.size && "sqm"}
              </InfoContent>
            </LocationInfo>
          )}
          {location?.startDate && (
            <LocationInfo>
              <InfoType>Start Date:</InfoType>
              <InfoContent>{location?.startDate}</InfoContent>
            </LocationInfo>
          )}
          {location?.website && (
            <LocationInfo>
              <InfoType>Website:</InfoType>
              <InfoContent>
                <Link href="{location?.website}</InfoContent>">
                  <a
                    href={location.website}
                    target="_blank"
                    className="no-link"
                    rel="noreferrer"
                  >
                    {location?.website}
                  </a>
                </Link>
              </InfoContent>
            </LocationInfo>
          )}
        </ContentList>
        {location?.content && (
          <LocationInfo>
            <InfoType>Main Occupiers:</InfoType>
            <InfoContent>{location?.content}</InfoContent>
          </LocationInfo>
        )}
        {location?.address && (
          <LocationInfo>
            <InfoType>Address:</InfoType>

            <InfoContentAddress>
              <a
                href={location.googleMapsLink}
                target="_blank"
                className="no-link"
                rel="noreferrer"
              >
                {location?.address}
              </a>
            </InfoContentAddress>
          </LocationInfo>
        )}
      </Content>
    </Wrapper>
  )
}

export default OpenWrapperLocation
