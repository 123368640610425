import { Collapse } from "@material-ui/core"
import React, { useState } from "react"
import { RecoilRoot } from "recoil"
import styled from "styled-components"

import ButtonWithRoundedEdges from "~components/ButtonWithRoundedEdges"
import Hidden from "~components/Hidden"
import Col from "~layout/Col"
import Container from "~layout/Container"
import Row from "~layout/Row"

// import Container from "@material-ui/core/Container"
// import Grid from "@material-ui/core/Grid"
import PortfolioFilter from "./PortfolioFilter"
import PortfolioFilterMobile from "./PortfolioFilterMobile"
import PortfolioMap from "./PortfolioMap"
const Wrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 50px;

  @media ${({ theme }) => theme.breakpoint.md} {
    margin-top: 100px;
    margin-bottom: 50px;
  }
  width: 100%;
`

const PortfolioTitle = styled.div`
  font-size: 30px;
  line-height: 33px;
  font-weight: 600;
  @media ${({ theme }) => theme.breakpoint.md} {
    font-size: 40px;
    font-weight: 600;
    line-height: 40px;
  }
  color: #003d6a;
  margin-bottom: 32px;
`

const MB65 = styled.div`
  margin-bottom: 65px;
`

const PortfolioSubtitle = styled.div`
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;
  @media ${({ theme }) => theme.breakpoint.md} {
    width: 424px;
  }
  color: #003d6a;
`
const StyledPortfolioFilterMobile = styled(PortfolioFilterMobile)`
  z-index: 100;
  position: fixed;
  bottom: -100%;
  bottom: ${props => (props.toggled ? "36px" : null)};
  left: 0px;
  padding: 20px;
  background: white;
  transition: 1s;
  width: 70%;
  border-top-right-radius: 36px;
  border: 2px solid #6fc2b4;
`
const StyledGridFiltering = styled(Col)`
  /* background: white;
  border-bottom-left-radius: 36px;
  z-index: 2;
  box-shadow: 0px 2px 36px rgba(0, 0, 0, 0.11); */
  position: relative;
`
const TogglingButtonWrapper = styled.div`
  position: fixed;
  bottom: 0px;
  left: 0;
  width: 70%;
  height: 37px;
  /* background: #ee751a; */
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  z-index: 98;
  & > div {
    width: 100%;
    text-align: center;
  }
  .button-container * {
    width: 100%;
  }
  .close {
    border-top-right-radius: 0px;
    border-bottom-left-radius: 0px;
  }
`
const AmendedPortfolioFilter = styled(PortfolioFilter)`
  background: white !important;
  border-top-right-radius: 36px;
  border-bottom-left-radius: 36px;
  z-index: 2;
  box-shadow: 0px 2px 36px rgba(0, 0, 0, 0.11);
  padding: 22px;
`

const MapWrapper = styled(Row)`
  margin-top: 30px;
`

const FilterButton = styled(ButtonWithRoundedEdges)`
  background: #6fc2b4 !important;
  color: white;
`
const PortfolioLocations = ({ title, subtitle, locations }) => {
  const [filteredLocations, setFilteredLocations] = useState(locations)
  const [toggled, setToggled] = useState(false)
  const handleFilteredLocationsChanged = filteredLocations => {
    setFilteredLocations(filteredLocations)
  }

  return (
    <RecoilRoot>
      <Container overflowDisabled>
        <MB65>
          <Wrapper>
            <Row>
              <Col xs={12} md={6} xl={6}>
                <PortfolioTitle>{title}</PortfolioTitle>
                <PortfolioSubtitle>{subtitle}</PortfolioSubtitle>
              </Col>
            </Row>
            <MapWrapper>
              <Hidden mdDown>
                <StyledGridFiltering style={{ zIndex: 2 }} lg={4} xl={3}>
                  <AmendedPortfolioFilter
                    style={{ zIndex: 2 }}
                    locations={locations}
                    onChangeFilteredLocations={handleFilteredLocationsChanged}
                  />
                </StyledGridFiltering>
              </Hidden>
              <Col style={{ zIndex: 1 }} xs={12} md={12} lg={8} xl={9}>
                <PortfolioMap locations={filteredLocations} />
              </Col>
            </MapWrapper>
            <Hidden lgUp>
              <TogglingButtonWrapper>
                <FilterButton
                  color="#6fc2b4"
                  borderColor="#6fc2b4"
                  className="close"
                  onClick={() => {
                    setToggled(!toggled)
                  }}
                  initialText={toggled ? "Close" : "Filter"}
                  disableAnimation
                />
              </TogglingButtonWrapper>

              <StyledPortfolioFilterMobile
                toggled={toggled}
                style={{ zIndex: 2 }}
                locations={locations}
                onChangeFilteredLocations={handleFilteredLocationsChanged}
              />
            </Hidden>
          </Wrapper>
        </MB65>
      </Container>
    </RecoilRoot>
  )
}

export default PortfolioLocations
