import "./filterstyles.css"

import {
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Slider,
} from "@material-ui/core"
import SearchIcon from "@material-ui/icons/Search"
import _ from "lodash"
import React, { useEffect, useState } from "react"
import { useRecoilState } from "recoil"
import styled from "styled-components"

import Hidden from "~components/Hidden"

import isOpenAtom from "./isOpenAtom"
import locationAtom from "./locationAtom"
import OpenWrapperLocation from "./OpenWrapperLocation"
import mapAtom from "~atoms/mapAtom"

const StyledInputBox = styled(OutlinedInput)`
  margin: 8px 0px;
  padding: 8px 8px;
  height: 40px;
  border: 2px solid #ee751a;
  border-radius: 32px;
`

const StyledSelect = styled(Select)`
  margin: 8px 0px;
  padding: 8px 8px;
  height: 40px;
  width: 100%;
  color: #b1c4d1;
  line-height: 26px;
  border: 2px solid #ee751a;
  border-radius: 32px;
  & .MuiSelect-root {
    & fieldset {
      border-color: #ee751a;
    }
    &:hover fieldset {
      border-color: #ee751a;
    }
    &.Mui-focused fieldset {
      border-color: #ee751a;
    }
  }
  &:after {
    display: none;
  }
  & .iconOutlined {
    color: #ee751a;
  }
  & .first-child {
    color: #b1c4d1;
  }
`

const StyledRadio = styled(Radio)`
  color: #ee751a;
  &.Mui-checked {
    color: #ee751a;
  }
`

const TitleText = styled.div`
  margin-bottom: 10px;
  margin-top: 10px;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;

  @media ${({ theme }) => theme.breakpoint.md} {
    font-size: 18px;
    font-weight: 700;
    line-height: 20px;
  }
`

const HeaderText = styled.div`
  margin-bottom: 5px;
  margin-top: 10px;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  @media ${({ theme }) => theme.breakpoint.md} {
    font-size: 15px;
    font-weight: 700;
    line-height: 20px;
  }
`

const ClearFilter = styled.div`
  font-size: 14px;
  font-weight: 300;
  line-height: 25px;
  letter-spacing: 0.1555556px;
  color: #ee751a;
  background-color: white;
  padding: 0px 8px;
  box-sizing: border-box;
  flex-shrink: 0;
  text-decoration: none;
  display: inline-block;
  line-height: 2em;
  border: 2px solid #ee751a;
  text-align: center;
  /* width: 36%; */
  font-weight: 500;
  border-top-right-radius: 22px;
  border-bottom-left-radius: 22px;
  text-align: center;
  width: 100%;
  cursor: pointer;
`
const SliderPoints = styled.div`
  display: flex;
  margin-bottom: 2rem;
  font-weight: 500;
  .push {
    margin-left: auto;
  }
  & div {
    font-size: 14px;
    font-weight: 300;
    line-height: 14px;
  }
`
const CenterDiv = styled.div`
  display: flex;
  /* width: 50%; */
  font-weight: 500;
  color: #2a5c82;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  margin-top: 20px;
`

const Filters = styled.div`
  /* margin: 32px 16px; */
  /* margin-top: 36px; */
  font-weight: 300;
  font-size: 1rem;
  line-height: 1;
  color: #003d6a;
  position: relative;
  z-index: 2;
`

const StyledOpenWrapperLocation = styled(OpenWrapperLocation)`
  position: fixed;
  left: -100vw;
  width: 100vw;
  height: 100vh;
  top: 0;
  z-index: 999;
  border-bottom-left-radius: 0px;
  transition-delay: 1s;

  &.active {
    transition-delay: 1s;

    left: 0;
    /* width: 100%; */
    /* height: 100%; */
  }
  /* height: calc(100% - 1px); */
  /* left: 1px; */
  overflow: hidden;
  transition: 0.5s ease all;
  /* width: calc(100% - 1px); */
  /* left: ${props => (props.isOpen ? "100%" : "1px")}; */
`
const Wrapper = styled.div`
  position: relative;
`

const PortfolioFilterMobile = ({
  locations,
  onChangeFilteredLocations,
  className,
}) => {
  const [name, setName] = useState("")
  const [directory, setDirectory] = useState("all")
  const [city, setCity] = useState("all")
  const [country, setCountry] = useState("all")
  const [portfolioType, setPortfolioType] = useState("all")
  const [investmentDate, setInvestmentDate] = useState("all")
  const [currentLocation, setCurrentLocation] = useRecoilState(locationAtom)
  const [isOpen, setOpen] = useRecoilState(isOpenAtom)
  const [position, setPosition] = useRecoilState(mapAtom)
  const [allCities, setCities] = useState()
  const [allCountries, setCountries] = useState()
  const MIN_SIZE = 0
  const MAX_SIZE = 100000
  const STEP_SIZE = 10000
  const [size, setSize] = useState([MIN_SIZE, MAX_SIZE])

  function handleDirectoryChange(event) {
    const {
      target: { value: _value },
    } = event
    setDirectory(_value)
  }

  function handleCityChange(event) {
    const {
      target: { value: _value },
    } = event
    setCity(_value)
  }
  useEffect(() => {
    setDirectory("live")
  }, [])

  function handleCountryChange(event) {
    setDirectory("all")
    setCity("all")
    const {
      target: { value: _value },
    } = event
    setCountry(_value)
  }

  function handlePortfolioTypeChange(event) {
    const {
      target: { value: _value },
    } = event
    setPortfolioType(_value)
  }

  function handleInvestmentDateChange(event) {
    const {
      target: { value: _value },
    } = event
    setInvestmentDate(_value)
  }

  function handleNameChange(event) {
    const {
      target: { value: _value },
    } = event
    setName(_value)
  }

  function handleSizeChange(e, data) {
    setSize(data)
  }

  const ShowCurrent = () => {
    // console.log("filter")
    // console.log(currentLocation)
    setOpen(false)
  }
  useEffect(() => {
    let SortedCities = locations.map(location => {
      if(location.city) {
        return location.city
      }
    })
    const uniqCities = [...new Set(SortedCities)];
    setCities(uniqCities.sort()) ; 
    

    let SortedCountries = locations.map(location => {
      if(location.country) {
        return location.country
      }
    })
    const uniqCountries = [...new Set(SortedCountries)];
    setCountries(uniqCountries.sort()) ; 
  }, [locations])
 

  useEffect(() => {
    // Directory Type
    let filteredLocations = locations.filter(
      location =>
        directory === "all" ||
        (location.live && directory === "live") ||
        (!location.live && directory === "historic")
    )
    // City
    filteredLocations = filteredLocations.filter(
      location => city === "all" || location.city === city
    )
    // Country
    filteredLocations = filteredLocations.filter(
      location => country === "all" || location.country === country
    )
    // Type
    filteredLocations = filteredLocations.filter(
      location => portfolioType === "all" || location.type === portfolioType
    )
    // Date
    filteredLocations = filteredLocations.filter(
      location =>
        investmentDate === "all" || location.startDate === investmentDate
    )

    // Size
    filteredLocations = filteredLocations.filter(
      location =>
        !location.size ||
        (location.size >= size[0] && location.size <= size[1]) ||
        (location.size > size[1] && size[1] === MAX_SIZE) ||
        (location.sie < size[0] && size[0] === MIN_SIZE)
    )
    // Name
    filteredLocations = filteredLocations.filter(
      location =>
        !name ||
        name === "" ||
        location.name.toUpperCase().indexOf(name.toUpperCase()) !== -1
    )
    setPosition({ lat: filteredLocations[0].latitude, lng: filteredLocations[0].longitude });
    onChangeFilteredLocations(filteredLocations)
  }, [directory, city, country, portfolioType, investmentDate, size, name])

  function clearFilter() {
    setDirectory("all")
    setCity("all")
    setCountry("all")
    setPortfolioType("all")
    setInvestmentDate("all")
    setSize([MIN_SIZE, MAX_SIZE])
  }

  return (
    <Wrapper>
      <Filters className={className} onClick={() => ShowCurrent()}>
        <TitleText>Pradera Portfolio Search</TitleText>

        <HeaderText>Directory</HeaderText>
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="directory"
            name="directory"
            value={directory}
            onChange={handleDirectoryChange}
          >
            <FormControlLabel
              value="all"
              control={<StyledRadio />}
              label="Show All"
            />
            <FormControlLabel
              value="historic"
              control={<StyledRadio />}
              label="Show Historic Portfolio only"
            />
            <FormControlLabel
              value="live"
              control={<StyledRadio />}
              label="Show Live Portfolio only"
            />
          </RadioGroup>
        </FormControl>
        <HeaderText>Country</HeaderText>
        <FormControl component="fieldset">
          <StyledSelect
            value={country}
            onChange={handleCountryChange}
            variant="outlined"
          >
            <MenuItem value="all">Show All</MenuItem>
            {locations &&
              allCountries?.map(location => (
                <MenuItem value={location}>{location}</MenuItem>
              ))}
          </StyledSelect>
        </FormControl>
        <HeaderText>Portfolio Type</HeaderText>
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="portfolio"
            name="portfolio"
            value={portfolioType}
            onChange={handlePortfolioTypeChange}
          >
            <FormControlLabel
              value="all"
              control={<StyledRadio />}
              label="Show All"
            />
            {locations &&
              _.uniqBy(locations, "type").map(location => (
                <FormControlLabel
                  value={location.type}
                  control={<StyledRadio />}
                  label={location.type}
                />
              ))}
          </RadioGroup>
        </FormControl>
        {/* <HeaderText>Portfolio Size</HeaderText>
        <Slider
          aria-labelledby="discrete-slider-small-steps"
          step={STEP_SIZE}
          marks
          min={MIN_SIZE}
          max={MAX_SIZE}
          value={size}
          onChange={handleSizeChange}
        />
        <SliderPoints>
          <div>
            min size <br /> {size[0]}
          </div>
          <div className="push">
            max size <br /> {size[1]} {size[1] === MAX_SIZE ? "+" : ""}
          </div>
        </SliderPoints> */}
        <CenterDiv>
          <ClearFilter onClick={clearFilter}>Clear Filter</ClearFilter>
        </CenterDiv>

        <StyledOpenWrapperLocation
          location={currentLocation}
          isOpen={isOpen}
          className={isOpen ? "active" : null}
        />
      </Filters>
    </Wrapper>
  )
}

export default PortfolioFilterMobile
